<button
  class="w-full h-full pl-2 pr-3 py-3 bg-opacity-10 rounded-md justify-start items-start gap-2 inline-flex cursor-pointer"
  (click)="onClick()"
  [ngStyle]="{ 'background-color': getBgColor() }"
  [disabled]="(AppStore.chatApiInProgress$ | async)?.inProgress"
>
  <div class="w-5 self-stretch justify-start items-start gap-2.5">
    <div class="w-5 h-5 relative">
      <i class="mr-2 min-w-[20px] h-5 mt-1">
        <img
          *ngIf="!customizeColors"
          class=""
          src="./assets/images/icons/search-bar/lightbulb-blue-icon.svg"
          alt=""
          class="w-5 h-5"
        />
        <svg
          *ngIf="customizeColors"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          [style.fill]="customizeColors ? customizeColorValues?.SecondaryColor : '#2653D9'"
          [style.fill-opacity]="customizeColors ? 1 : 0.54"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.99984 1.6665C6.78317 1.6665 4.1665 4.28317 4.1665 7.49984C4.1665 9.48317 5.15817 11.2248 6.6665 12.2832V14.1665C6.6665 14.6248 7.0415 14.9998 7.49984 14.9998H12.4998C12.9582 14.9998 13.3332 14.6248 13.3332 14.1665V12.2832C14.8415 11.2248 15.8332 9.48317 15.8332 7.49984C15.8332 4.28317 13.2165 1.6665 9.99984 1.6665ZM7.49984 17.4998C7.49984 17.9582 7.87484 18.3332 8.33317 18.3332H11.6665C12.1248 18.3332 12.4998 17.9582 12.4998 17.4998V16.6665H7.49984V17.4998ZM11.6665 11.4165L12.3748 10.9165C13.4998 10.1332 14.1665 8.85817 14.1665 7.49984C14.1665 5.19984 12.2998 3.33317 9.99984 3.33317C7.69984 3.33317 5.83317 5.19984 5.83317 7.49984C5.83317 8.85817 6.49984 10.1332 7.62484 10.9165L8.33317 11.4165V13.3332H11.6665V11.4165Z"
          />
        </svg>
      </i>
    </div>
  </div>
  <div class="text-left grow shrink basis-0 text-blue-950 text-sm font-medium leading-tight">
    {{ followUpQuestion }}
  </div>
</button>
