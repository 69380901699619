import { HttpClient } from "@angular/common/http";
import { Injectable, NgModule, isDevMode } from "@angular/core";
import { TRANSLOCO_LOADER, Translation, TranslocoLoader, TranslocoModule, provideTransloco } from "@ngneat/transloco";
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "./const/app-constant";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

const systemLang = navigator.language;
const defaultLang = AVAILABLE_LANGUAGES.find((lang) => lang == systemLang) || DEFAULT_LANGUAGE;

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: AVAILABLE_LANGUAGES,
        defaultLang,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        fallbackLang: "en_gb",
      },
    }),
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
