import { MatPaginator } from "@angular/material/paginator";
import { TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { AFFILIATE_LINK, DASHBOARD_PAGE_SIZE, EMBEDDING_FILE_ICON_MAP, LS_TOKEN, LS_USER_DATA, LS_USER_ID } from "app/const/app-constant";
import { UserData } from "app/pages/home/home.model";

export function isLocal() {
  return location.hostname === "localhost";
}

export function isMobileDevice() {
  return !!navigator.userAgent.match(/android|iphone|ipad|iPod/i);
}

export function isApple() {
  return !!navigator.userAgent.match(/iphone|ipad|iPod/i);
}

export function setTokenAndUserData() {
  const token = localStorage.getItem(LS_TOKEN);
  if (token) {
    AppStore.isLoggedIn$.next(true);
    const userData = localStorage.getItem(LS_USER_DATA);
    if (userData) {
      AppStore.userData$.next(JSON.parse(userData));
    }

    const userId = localStorage.getItem(LS_USER_ID);
    if (userId) {
      AppStore.userId$.next(userId);
    }
  }
}

export function replaceParamsWithValue(url: string, params: Record<string, string>) {
  Object.keys(params).forEach((param) => {
    url = url.replace(`:${param}`, params[param]);
  });
  return url;
}

export function generateRandomString(length: number, withNumbers = false) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  if (withNumbers) {
    characters += "0123456789";
  }
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function removeTokenForLogout() {
  localStorage.removeItem(LS_TOKEN);
  localStorage.removeItem(LS_USER_DATA);
  localStorage.removeItem(LS_USER_ID);
  localStorage.removeItem(DASHBOARD_PAGE_SIZE);
  AppStore.isLoggedIn$.next(false);
  AppStore.userData$.next(null);
  AppStore.userId$.next(null);
  AppStore.userDataLimits$.next(null);
}

export function getUserData() {
  const str = localStorage.getItem(LS_USER_DATA);
  if (str) {
    return JSON.parse(str) as UserData;
  }
  return null;
}

export function isBrowserChrome(): boolean {
  const isChrome =
    navigator.userAgent.toLowerCase().indexOf("chrome") > -1 &&
    navigator.userAgent.toLowerCase().indexOf("edg") === -1 &&
    navigator.userAgent.toLowerCase().indexOf("chromium") === -1;
  return isChrome;
}

export function dateDifferenceInSec(dateComparing: string, currentDate: Date = new Date()) {
  const formattedDate = new Date(dateComparing);
  const dateDifference: number = currentDate.getTime() - formattedDate.getTime();
  return dateDifference / 1000;
}

export function getFormattedBrainName(query: string): string {
  if (query.length <= 50) {
    return query;
  } else {
    return query.substring(0, 50) + "...";
  }
}

export function getFormattedBrainNameDate(): string {
  const now = new Date();

  const minuteNow = now.getMinutes();
  const hourNow = now.getHours();
  const dayNow = now.getDate();
  const monthNow = now.getMonth() + 1; // Month is zero-based
  const yearNow = now.getFullYear();

  const formattedDate = `Brain ${hourNow}:${minuteNow} ${dayNow}.${monthNow}.${yearNow}`;
  return formattedDate;
}

export function isAutomaticBrainName(input: string): boolean {
  const pattern = /^Brain \d{1,2}:\d{2} \d{1,2}\.\d{1,2}\.\d{4}$/;
  return pattern.test(input);
}

export function translatePaginationBar(transloco: TranslocoService, itemsPerPageKey: string, paginator: MatPaginator) {
  return transloco.selectTranslateObject("pagination", {}, transloco.getActiveLang()).subscribe((translations) => {
    paginator._intl.itemsPerPageLabel = translations[itemsPerPageKey];
    paginator._intl.firstPageLabel = translations["firstPageLabel"];
    paginator._intl.lastPageLabel = translations["lastPageLabel"];
    paginator._intl.nextPageLabel = translations["nextPageLabel"];
    paginator._intl.previousPageLabel = translations["previousPageLabel"];
    paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      const amountPages = Math.ceil(length / pageSize);
      return transloco.translate("pagination.XoutOfY", {
        x: page + 1,
        y: amountPages,
      });
    };
    paginator._intl.changes.next();
  });
}

export function redirectToAffiliates() {
  window.open(AFFILIATE_LINK, "_blank");
}

export function addAlpha(color?: string, opacity?: number): string {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export function getCurrentYear(): number {
  return new Date().getFullYear();
}

export function getFileIconPath(typeOrExt: string|undefined): string {
  const type = typeOrExt?.replace(".", "");
  const iconName = type && EMBEDDING_FILE_ICON_MAP[type] || "file-default.svg";
  return `./assets/images/icons/brain-content/${iconName}`;
}
