import { NgModule } from "@angular/core";
import { BrainCardUrlPipe } from "app/pipes/brain-card-url.pipe";
import { CodeFormatPipe } from "./code-format.pipe";
import { CodeSplitPipe } from "./code-split.pipe";
import { CustomDatePipe } from "./custom-date.pipe";
import { UrlExtractionPipe } from "./url-extraction.pipe";

@NgModule({
  declarations: [BrainCardUrlPipe, CustomDatePipe, UrlExtractionPipe, CodeFormatPipe, CodeSplitPipe],
  imports: [],
  exports: [BrainCardUrlPipe, CustomDatePipe, UrlExtractionPipe, CodeFormatPipe, CodeSplitPipe],
})
export class PipesModule {}
