import { BehaviorSubject, Subject } from "rxjs";
import { InternetSearchStatus, LoginScreen, EmbeddingFile } from "./app.model";
import { UserData, UserDataLimits } from "./pages/home/home.model";
import { Conversation } from "app/api-models/conversation.models";
import {UsedEmbeddings} from "app/const/app-constant";
import { Brain } from "./pages/dashboard/dashboard.model";
import { BrainChat } from "./pages/my-brain/my-brain.model";

export class AppStore {
  public static isLoggedIn$ = new BehaviorSubject<boolean>(false);
  public static userData$ = new BehaviorSubject<UserData | null>(null);
  public static userId$ = new BehaviorSubject<string | null>(null);
  public static selectedBrainId$ = new BehaviorSubject<string | null>(null);
  public static brainProjects$ = new BehaviorSubject<Brain[]>([]);
  public static brainConversationId$ = new BehaviorSubject<string | undefined>(undefined);
  public static selectedBrain$ = new BehaviorSubject<Brain | null>(null);
  public static questionAsked$ = new BehaviorSubject<string>("");
  public static sidebarActive$ = new BehaviorSubject<boolean>(false);
  public static footerActive$ = new BehaviorSubject<boolean>(true);
  public static backToMainScreen$ = new Subject();
  public static screenChange$ = new Subject<LoginScreen>();
  public static allChats$ = new BehaviorSubject<Conversation[]>([]);
  public static chatApiInProgress$ = new BehaviorSubject<BrainChat>({ brainId: "", inProgress: false });
  public static askQuesFromSuggestedQuestion$ = new Subject<string>();
  public static brainContents$ = new BehaviorSubject<EmbeddingFile[]>([]);
  public static addedBrainContents$ = new BehaviorSubject<EmbeddingFile[]>([]);
  public static contentProcessingCompleted$ = new Subject<string>();
  public static selectedLanguage$ = new BehaviorSubject<string>("en_gb");
  public static logout$ = new Subject();
  public static entireWebpageIngestionInProgress$ = new BehaviorSubject<string>("");
  // used only for telling the dashboard that it was redirected from dashboard page.
  // Do not use anywhere else
  public static isRedirectedFromMain$ = new BehaviorSubject<boolean>(true);
  public static answerStream$ = new BehaviorSubject<{ stop: boolean; char: string }>({ stop: true, char: "" });
  public static answer$ = new BehaviorSubject<string>("");
  public static userDataLimits$ = new BehaviorSubject<UserDataLimits | null>(null);
  public static isIndexing$ = new BehaviorSubject<boolean>(false);
  public static internetSearchStatus$ = new BehaviorSubject<InternetSearchStatus | null>(null);
  public static initialChat$ = new BehaviorSubject<boolean>(false);
  public static onTabChange$ = new BehaviorSubject<string>(UsedEmbeddings);
}
