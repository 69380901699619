/* eslint-disable max-lines */
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AddedBrainContentComponent } from "app/core/components/added-brain-content/added-brain-content.component";
import { WebPageUploadComponent } from "app/core/components/web-page-upload/web-page-upload.component";
import { getUserData } from "app/core/functions/helper-functions";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import {
    BrainContentType,
    BubblePostResp,
    EmbeddingFile,
    EmbeddingFileStatus,
    FileCreationRequest,
} from "app/app.model";
import { AppService } from "app/app.service";
import { FILE_ERROR_MESSAGES, INGESTION_FAILED, LS_USER_ID } from "app/const/app-constant";
import { getDefaultApiResponseObj } from "app/core/modules/http-client/http-client.service";
import { BrainLimitDirective } from "app/directives/brain-limit.directive";
import { environment } from "app/environments/environment";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { UserDataLimits } from "app/pages/home/home.model";
import { EmbeddingFileService } from "app/services/embedding-files.service";
import { UsersService } from "app/services/users.service";
import { UpsertThreadEmbeddingFiles } from "app/pages/my-brain/my-brain.model";
import { FileAnalysisService } from "app/services/file-analysis.service";
import { Observable, concatMap, filter, forkJoin, from, map, mergeMap, of, retry, switchMap, tap, toArray } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { FileUploadResponse } from "../add-my-brain-content/add-my-brain-content.model";
import { AddMyBrainContentService } from "../add-my-brain-content/add-my-brain-content.service";
import { BaseHttpComponent } from "../base-http/base-http.component";
import { BrainContentComponent } from "../brain-content/brain-content.component";

@Component({
  selector: "app-file-upload",
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    BrainContentComponent,
    TranslocoModule,
    FormsModule,
    MatProgressSpinnerModule,
    BrainLimitDirective,
    AddedBrainContentComponent,
    WebPageUploadComponent,
    MatExpansionModule,
  ],
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FileUploadComponent extends BaseHttpComponent implements OnInit {
  isError = false;
  errorMessage: string[] = [];
  extensions = environment.allowedFiles;
  maxSize = 25 * 1024 * 1000;
  selectedBrain?: Brain;
  userData = getUserData();
  userDataLimits!: UserDataLimits;
  contents: EmbeddingFile[] = [];
  completedContents = 0;
  filesStr = this.extensions
    .map((x) => `${x}`)
    .join(", ")
    .toUpperCase();
  filesAccept = this.filesStr.split(", ").map(ext => "." + ext).join(", ").toLowerCase();
  

  @ViewChild("uploadFile") fileInput?: ElementRef;
  @Output() fileBrainContents = new EventEmitter<EmbeddingFile[]>();
  @Output() isUploading = new EventEmitter<boolean>();
  uploadingInProgress = false;

  constructor(
    private embeddingFileService: EmbeddingFileService,
    private userService: UsersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs$.add(
      AppStore.selectedBrain$.subscribe((brain) => {
        if (brain) this.selectedBrain = brain;
        this.cdr.markForCheck();
      }),
    );

    this.subs$.add(
      AppStore.userDataLimits$.subscribe((resp) => {
        if (resp) {
          this.userDataLimits = resp as UserDataLimits;
        }
      }),
    );
  }

  private validateFiles(files: File[]) {
    this.errorMessage = [];
    this.isError = false;
    const validFiles: File[] = [];
    files.forEach((file) => {
      const extension = file.name.split(".").reverse()[0];
      if (file && this.extensions.indexOf(extension) === -1) {
        this.errorMessage.push(
          this.transloco.translate("addMyBrainContent.file") +
            ` ${file.name} ` +
            this.transloco.translate("addMyBrainContent.notSupported") +
            ` ${this.extensions.join(", ")}`,
        );
        return;
      } else if (file && file.size > this.maxSize) {
        this.errorMessage.push(
          this.transloco.translate("addMyBrainContent.fileSize") +
            ` ${(file.size / (1000 * 1000)).toFixed(2)}` +
            this.transloco.translate("addMyBrainContent.maxLength") +
            `${this.maxSize / (1000 * 1000)}mb`,
        );
      } else if (this.contents.find((fileData) => fileData.fileName === file.name)) {
        this.errorMessage.push(this.transloco.translate("addMyBrainContent.fileUploaded"));
      } else {
        validFiles.push(file);
      }
    });

    this.isError = this.errorMessage.length > 0;
    return validFiles;
  }
  private getFiles(list: FileList) {
    const files: File[] = [];
    if (this.userData) {
      for (
        let index = 0;
        index < list.length && index < this.userData.contentFileLimit - this.userData.contentFileCount;
        index++
      ) {
        files.push(list[index]);
      }
    }

    return files;
  }

  onFileChange(event: Event) {
    this.isError = false;
    this.errorMessage = [];
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length) {
      const files = this.getFiles(input.files);
      const validFiles = this.validateFiles(files);
      if (this.fileInput) {
        this.fileInput.nativeElement.value = "";
      }

      if (!validFiles.length) return;
      this.uploadAndAddAsBrainContent(validFiles);
    }
  }

  uploadAndAddAsBrainContent(filesToUpload: File[]) {
    if (filesToUpload.length) {
      this.isUploading.emit(true);
      this.uploadingInProgress = true;

      const uploadingContents = filesToUpload.map((file) => {
        return {
          fileName: file.name,
          metadata: { title: file.name },
          contentType: BrainContentType.File,
          fileExtension: file.name.split(".").reverse()[0],
          status: EmbeddingFileStatus.Uploading,
          fileSize: file.size / 1000,
        } as Partial<EmbeddingFile>;
      }) as EmbeddingFile[];

      this.contents.push(...uploadingContents);

      this.cdr.markForCheck();

      const uploadAndProcessFile = (file: File) => {
        if (this.selectedBrain && this.userData) {
          return this.embeddingFileService.uploadFile(file).pipe(
            mergeMap((response) => {
              if (response.isSuccess && response.data) {
                const fileData = response.data[0];
                fileData.name = file.name;
                const currentContent = this.contents.find((x) => x.fileName == file.name);
                const currentContentIndex = this.contents.findIndex((x) => x.fileName == file.name);
                if (currentContent) {
                  currentContent.status = EmbeddingFileStatus.Processing;
                  this.contents[currentContentIndex] = currentContent;
                  this.cdr.markForCheck();
                }

                fileData.contentType = fileData.contentType?.replaceAll(".", "");
                const creationRequest: FileCreationRequest = {
                  projectId: this.selectedBrain?.id as string,
                  userId: this.userData?.id as string,
                  fileData: fileData,
                };
                return this.embeddingFileService.initiateFileData(creationRequest).pipe(
                  mergeMap((initiationResult) => {
                    if (initiationResult.isSuccess && initiationResult.data) {
                      let currentContent = this.contents.find((x) => x.fileName == file.name);
                      const currentContentIndex = this.contents.findIndex((x) => x.fileName == file.name);
                      if (currentContent) {
                        currentContent = initiationResult.data;
                        this.contents[currentContentIndex] = currentContent;
                        this.cdr.markForCheck();
                      }
                      this.completedContents = this.contents.length;
                      this.fileBrainContents.emit(this.contents);
                      this.isUploading.emit(false);

                      return this.userService.get(initiationResult.data?.createdBy).pipe(
                        mergeMap((response) => {
                          if (response && response.isSuccess && response.data) {
                            AppStore.userData$.next(response.data);
                            this.userData = response.data;
                            this.uploadingInProgress = false;
                            this.cdr.markForCheck();
                            // @ts-ignore
                            return this.embeddingFileService.ingestFile(initiationResult.data).pipe(
                              map((ingestResult) => ({
                                file,
                                response,
                                initiationResult,
                                ingestResult,
                              })),
                            );
                          } else {
                            return of({ file, response, initiationResult, ingestResult: null });
                          }
                        }),
                      );
                    } else {
                      this.errorMessage.push(this.transloco.translate("addMyBrainContent.fileUploaded"));
                      this.contents = this.contents.filter(
                        (x) => x.fileName?.toLowerCase() == file.name?.toLowerCase(),
                      );
                    }
                    return of({ file, response, initiationResult, ingestResult: null });
                  }),
                );
              }
              return of({ file, response, initiationResult: null, ingestResult: null });
            }),
          );
        }
        return of({ file, response: null, initiationResult: null, ingestResult: null });
      };

      from(filesToUpload)
        .pipe(
          mergeMap(uploadAndProcessFile, 5), // Adjust the concurrency level as needed
        )
        .subscribe({
          next: (result) => {
            if (result.ingestResult?.isSuccess && result.ingestResult.data) {
              let currentContent = this.contents.find(
                (x) => x.fileName?.toLowerCase() == result.ingestResult.data?.fileName?.toLowerCase(),
              );
              const currentContentIndex = this.contents.findIndex(
                (x) => x.fileName?.toLowerCase() == result.ingestResult.data?.fileName?.toLowerCase(),
              );
              if (currentContent) {
                currentContent = result.ingestResult.data;
                this.contents[currentContentIndex] = currentContent;
                this.fileBrainContents.next(this.contents);
                this.cdr.markForCheck();
              }
            }
          },

          error: (err) => {
            console.error(err);
            this.isUploading.emit(false);
            this.uploadingInProgress = false;
            this.cdr.markForCheck();
          },
          complete: () => {
            this.isUploading.emit(false);
            this.uploadingInProgress = false;
            this.cdr.markForCheck();
          },
        });
    }
  }

  deleteContent(currentContent: EmbeddingFile) {
    if (this.errorMessage) {
      this.errorMessage = [];
    }

    const content = this.contents.find(
      (x) => x.fileName === currentContent.fileName || x.metadata?.title === currentContent.fileName,
    );

    if (!content) {
      return;
    }

    this.embeddingFileService
      .delete(content.id)
      .pipe(
        mergeMap((res) => {
          if (res && res.isSuccess && res.data) {
            this.contents = this.contents.filter((c) => c.id !== content.id);
            this.fileBrainContents.emit(this.contents);
            this.completedContents = this.contents.length;
            this.cdr.markForCheck();
            return this.userService.get(content.createdBy);
          }

          return of(null);
        }),
      )
      .subscribe((result) => {
        if (result && result.isSuccess && result.data) {
          AppStore.userData$.next(result.data);
          this.userData = result.data;
          this.cdr.markForCheck();
        }
      });
  }
}
