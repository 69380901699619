// local storage keys

export const LS_TOKEN = "accessToken";
export const LS_USER_ID = "userId";
export const LS_USER_DATA = "userData";

export const AVAILABLE_LANGUAGES = ["en_gb", "de_de", "es_es", "fr_fr", "zh_cn", "ko_ko", "ar_ar"];

export const DEFAULT_LANGUAGE = "en_gb";

// routes
export const DASHBOARD_ROUTE = "dashboard";
export const CONTENT_ROUTE_ID = "content/:id/:tab";
export const MY_BRAIN_ROUTE = "brain/:id";
export const EMBEDDED_CHAT = "embedded-chat/:id";
export const CHAT_WINDOW = "chat-window/:id";
export const SHARE_BRAIN_ROUTE_ID = "share-brain/:id";
export const HOME_ROUTE = "";
export const CREATE_ACCOUNT_ROUTE = "create-account";
export const ACCOUNT_DETAILS_ROUTE = "account-details";
export const CRAWLED_PAGES_ROUTE = "crawled-pages/:id";
export const BUSINESS_LANDING_PAGE_ROUTE = "business";
export const AFFILIATE_ROUTE = "affiliate";
export const AFFILIATE_COMPLETE_ROUTE = "affiliate-complete";
export const AUDIO_ENCODING = "LINEAR16";
export const SAMPLE_RATE_HERTZ = 16000;
export const LANGUAGE_MAP = {
  en_gb: "en-US",
  es_es: "es-ES",
  de_de: "de-DE",
  fr_fr: "fr-FR",
  ar_ar: "ar-SA",
  ko_ko: "ko-KR",
  zh_cn: "zh-CN",
};

export const COUNTRY_MAP = {
  AE: "ar_ar",
  BH: "ar_ar",
  DZ: "ar_ar",
  EG: "ar_ar",
  IQ: "ar_ar",
  JO: "ar_ar",
  KW: "ar_ar",
  LB: "ar_ar",
  LY: "ar_ar",
  MA: "ar_ar",
  OM: "ar_ar",
  QA: "ar_ar",
  SA: "ar_ar",
  SY: "ar_ar",
  TN: "ar_ar",
  YE: "ar_ar",
  DE: "de_de",
  AT: "de_de",
  CH: "de_de",
  LI: "de_de",
  ES: "es_es",
  AR: "es_es",
  BO: "es_es",
  CL: "es_es",
  CO: "es_es",
  CR: "es_es",
  CU: "es_es",
  DO: "es_es",
  EC: "es_es",
  SV: "es_es",
  GQ: "es_es",
  GT: "es_es",
  HN: "es_es",
  MX: "es_es",
  NI: "es_es",
  PA: "es_es",
  PY: "es_es",
  PE: "es_es",
  PR: "es_es",
  UY: "es_es",
  VE: "es_es",
  FR: "fr_fr",
  BE: "fr_fr",
  CA: "fr_fr",
  CI: "fr_fr",
  CM: "fr_fr",
  CONGO: "fr_fr",
  DRC: "fr_fr",
  GA: "fr_fr",
  GN: "fr_fr",
  LU: "fr_fr",
  MC: "fr_fr",
  ML: "fr_fr",
  NE: "fr_fr",
  RW: "fr_fr",
  SN: "fr_fr",
  TG: "fr_fr",
  KR: "ko_ko",
  CN: "zh_cn",
  SG: "zh_cn",
};

export const MAX_INGESTION_TIME_MIN = 30;

export const DEFAULT_FOLLOWUP_QUESTIONS = [
  "defaultFollowUpQuestion.first",
  "defaultFollowUpQuestion.second",
  "defaultFollowUpQuestion.third",
];

export const INGESTION_FAILED = "ingestionFailed";
export const IS_LIMIT_VISIBLE = "isLimitVisible";
export const DASHBOARD_PAGE_SIZE = "dashboardPageSize";
export const AFFILIATE_LINK = "https://affiliates.reflio.com/invite/mybrain";
export const MONTHLY_QUESTION_LIMIT_THRESHOLD = {
  Free: 45,
  Enthusiast: 480,
  Professional: 4900,
};
export const APPROACHING_QUESTION_LIMIT = "d-617ee0ee27504b63bdd17355de5bd808";
export const REACHED_QUESTION_LIMIT = "d-2b6766c50cd44e23a54a3e59539dae6e";
export const UNSUB_ID = 23194;

export const FILE_ERROR_MESSAGES = {
  FILE_NOT_SUPPORTED: "File not supported",
};

export const SSO_TOKEN = "sso_token";

export const UsedEmbeddings = "used";
export const ProcessingEmbeddings = "waitlist";

export const EMBEDDING_FILE_ICON_MAP: { [key: string]: string } = {
  pdf: "file-pdf.svg",
  doc: "file-doc.svg",
  docx: "file-doc.svg",
  odt: "file-odt.svg",
  epub: "file-epub.svg",
  pages: "file-pages.svg",
  pptx: "file-pptx.svg",
  xls: "file-excel.svg",
  xlsx: "file-excel.svg",
  csv: "file-csv.svg",
  txt: "file-txt.svg",
};
