import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslocoModule, TranslocoPipe } from "@ngneat/transloco";
import { AskMyBrainSearchComponent } from "app/core/components/ask-my-brain-search/ask-my-brain-search.component";
import { FollowUpQuestionComponent } from "app/core/components/follow-up-question/follow-up-question.component";
import { NoBrainComponent } from "app/core/components/no-brain/no-brain.component";
import { UserLimitComponent } from "app/core/components/user-limit/user-limit.component";
import { HeaderComponent } from "app/layout/components/header/header.component";
import { PipesModule } from "app/pipes/pipes.module";
import { MarkdownModule } from "ngx-markdown";
import { EmbeddedChatComponent } from "../embedded-chat/embedded-chat.component";

@Component({
  selector: "app-chat-window",
  standalone: true,
  imports: [
    CommonModule,
    FollowUpQuestionComponent,
    PipesModule,
    TranslocoPipe,
    TranslocoModule,
    AskMyBrainSearchComponent,
    MatSnackBarModule,
    HeaderComponent,
    UserLimitComponent,
    MatTooltipModule,
    MatButtonModule,
    NoBrainComponent,
    MarkdownModule,
  ],
  templateUrl: "./chat-window.component.html",
  styleUrls: ["../embedded-chat/embedded-chat.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChatWindowComponent extends EmbeddedChatComponent {}
