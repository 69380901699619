import { inject, Injectable } from "@angular/core";
import { HttpClientService } from "app/core/modules/http-client/http-client.service";
import { environment } from "app/environments/environment";
import { UserData } from "app/pages/home/home.model";
import { UserPortalResponse, UserStripeInformation } from "app/services/membership.model";
import { Router } from "@angular/router";
import { CheckoutSessionRequest } from "app/app.model";

@Injectable({
  providedIn: "any",
})
export class UsersService {
  protected router = inject(Router);
  constructor(private http: HttpClientService) {}

  get(id?: string, isAuth = true) {
    return this.http.get<UserData>(`${environment.dotNetBaseUrl}/api/user/${id}`, { isAuth: isAuth });
  }

  update(user: UserData) {
    return this.http.put<UserData>(`${environment.dotNetBaseUrl}/api/user`, user);
  }

  generateUserApiKey(id: string) {
    return this.http.get<UserData>(`${environment.dotNetBaseUrl}/api/user/generate-user-api-key?userId=${id}`);
  }

  getStripeInformation(userId: string) {
    return this.http.get<UserStripeInformation>(
      `${environment.dotNetBaseUrl}/api/user/stripe-information?userId=${userId}`,
    );
  }

  createCheckoutSession(request: CheckoutSessionRequest) {
    return this.http.post<UserData>(`${environment.dotNetBaseUrl}/api/user/checkout`, request, { maxRetryCount: 1 });
  }

  cancelSubscription(userId: string) {
    return this.http.post<UserData>(`${environment.dotNetBaseUrl}/api/user/cancel-subscription?userId=${userId}`, {});
  }

  getUserPortal(userId: string) {
    const body = {
      userId: userId,
      returnUrl: `${environment.baseUrl}/account-details`,
    };

    return this.http.post<UserPortalResponse>(`${environment.dotNetBaseUrl}/api/user/user-portal`, body);
  }
}
