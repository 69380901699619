import { Injectable } from "@angular/core";
import {
  BrainContentType,
  EmbeddingFile,
  FileCreationRequest,
  FileUploadResponse,
  GenericBAResponse,
  StatusQueryType,
} from "app/app.model";
import { HttpClientService } from "app/core/modules/http-client/http-client.service";
import { environment } from "app/environments/environment";

@Injectable({
  providedIn: "any",
})
export class EmbeddingFileService {
  constructor(private http: HttpClientService) {}

  get(id: string | undefined) {
    return this.http.get<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/${id}`, { maxRetryCount: 1 });
  }
  addFiles(files: File[], projectId: string, userId: string) {
    const formData = new FormData();
    files.forEach((f) => {
      formData.append("files", f, f.name);
    });
    const requestData = { projectId: projectId, userId: userId };
    formData.append("data", JSON.stringify(requestData));
    return this.http.post<EmbeddingFile[]>(`${environment.dotNetBaseUrl}/api/embeddingFile/files`, formData);
  }

  addFile(file: File, projectId: string, userId: string) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const requestData = { projectId: projectId, userId: userId };
    formData.append("data", JSON.stringify(requestData));
    return this.http.post<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/file/upload`, formData);
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append("files", file);
    return this.http.post<FileUploadResponse[]>(`${environment.s3EndpointUrl}`, formData);
  }

  initiateFileData(creationRequest: FileCreationRequest) {
    return this.http.post<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/file/data`, creationRequest);
  }

  ingestFile(embeddingFile: EmbeddingFile) {
    return this.http.post<EmbeddingFile>(
      `${environment.dotNetBaseUrl}/api/embeddingProject/file/ingest`,
      embeddingFile,
    );
  }

  addWebpage(embeddingFile: EmbeddingFile) {
    return this.http.post<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/webpage`, embeddingFile);
  }

  addWebsite(embeddingFile: EmbeddingFile) {
    return this.http.post<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/website`, embeddingFile, {
      maxRetryCount: 1,
    });
  }

  getBrainEmbeddingFiles(brainId: string, status: StatusQueryType, page = 1, pageSize = 100000) {
    return this.http.get<GenericBAResponse<EmbeddingFile>>(
      // eslint-disable-next-line max-len
      `${environment.dotNetBaseUrl}/api/embeddingFile/getBy?ProjectId=${brainId}&Status=${status}&page=${page}&pageSize=${pageSize}`,
    );
  }

  delete(id?: string) {
    return this.http.delete<boolean>(`${environment.dotNetBaseUrl}/api/embeddingFile?id=${id}`);
  }

  batchDelete(content: EmbeddingFile[]) {
    return this.http.delete<boolean>(`${environment.dotNetBaseUrl}/api/embeddingFile/batchDelete`, content);
  }

  countBy(id?: string) {
    return this.http.get<number>(`${environment.dotNetBaseUrl}/api/embeddingFile/countBy?ProjectId=${id}`);
  }

  countByType(id?: string, type?: BrainContentType) {
    return this.http.get<number>(`${environment.dotNetBaseUrl}/api/embeddingFile/countBy?ProjectId=${id}&type=${type}`);
  }

  countByStatus(id?: string, status?: StatusQueryType) {
    return this.http.get<number>(
      `${environment.dotNetBaseUrl}/api/embeddingFile/countBy?ProjectId=${id}&status=${status}`,
    );
  }

  retry(embeddingFile: EmbeddingFile) {
    return this.http.post<EmbeddingFile>(`${environment.dotNetBaseUrl}/api/embeddingFile/retry`, embeddingFile);
  }
}
