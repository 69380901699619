<div
  class="relative max-w-[114px] min-w-[114px] h-auto border border-solid border-search-border-color rounded-lg p-2"
  *transloco="let t; read: 'brainContent'"
>
  <!-- Cancel Button  -->
  <button class="close-button" (click)="deleteContent()">
    <img src="./assets/images/icons/search-bar/close-upload.svg" *ngIf="!content.isDeleting; else spinner" />
    <ng-template #spinner><mat-spinner [diameter]="14"></mat-spinner></ng-template>
  </button>

  <!-- orange file upload icon  -->
  <div class="relative max-w-[18px] h-auto">
    <div class="inline-flex" *ngIf="content.status === status.Processing; else showContentIcon">
      <mat-spinner [diameter]="20"></mat-spinner>
      <span class="ml-1">{{ t("adding") }}</span>
    </div>
    <ng-template #showContentIcon [ngSwitch]="content.contentType">
      <img src="./assets/images/icons/brain-content/globe.svg" *ngSwitchCase="BrainContentType.Website" />
      <img src="./assets/images/icons/brain-content/web-page-single.svg" *ngSwitchCase="BrainContentType.Link" />
      <img [src]="getFileIconPath(content.fileExtension)" *ngSwitchDefault/>
    </ng-template>
  </div>
  <!-- information description  -->
  <div class="relative">
    <div class="light-text truncate text-left" *ngIf="content.contentType === BrainContentType.Link">
      {{ t("singlePage") }}
    </div>

    <div class="light-text truncate text-left" *ngIf="content.contentType === BrainContentType.Website">
      {{ "brainContent.AllPages" | transloco }}
    </div>
    <div
      class="dark-text truncate text-left py-3"
      *ngIf="content.contentType === BrainContentType.File; else webpageLink"
      [matTooltip]="content.metadata?.title || ''"
    >
      {{ content.metadata?.title }}
    </div>
    <ng-template #webpageLink>
      <div class="dark-text truncate text-left" [matTooltip]="(content.webpage | BrainCardUrlPipe) || ''">
        {{ content.webpage | BrainCardUrlPipe }}
      </div>
    </ng-template>
  </div>
</div>
