<h2 mat-dialog-title class="dialog-head">
  <div class="dialog-head flex justify-between items-center" *transloco="let t; read: 'leftSidebar'">
    <div class="text-lg font-medium text-black-dark">
      {{ title }}
    </div>
  </div>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="w-full px-0 md:w-[420px]">
    <div class="text-base text-black-lighter" [innerHTML]="confirmBodyTxt"></div>
  </div>
</mat-dialog-content>
<div class="no-border">
  <mat-dialog-actions align="end" class="!border-0 !p-0 !pt-4">
    <button mat-button mat-dialog-close class="px-0" [mat-dialog-close]="ConfirmDialogOption.cancel">
      {{ cancelButtonTxt }}
    </button>
    <button mat-stroked-button color="warn" [mat-dialog-close]="ConfirmDialogOption.ok">{{ okButtonTxt }}</button>
  </mat-dialog-actions>
</div>
