import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { Subscription } from "rxjs/internal/Subscription";

@Component({
  selector: "app-base-component",
  standalone: true,
  imports: [],
  templateUrl: "./base-component.component.html",
  styleUrls: ["./base-component.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent implements OnDestroy {
  protected cdr = inject(ChangeDetectorRef);
  protected subs$ = new Subscription();
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected snackbar = inject(MatSnackBar);
  protected transloco = inject(TranslocoService);

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  successMsg(
    msg: string,
    action: string | undefined = undefined,
    duration = 5000,
    isContentExceeded = false,
    isQuestionExceeded = false,
  ) {
    if (!isContentExceeded && !isQuestionExceeded) {
      this.snackbar.open(msg, action, {
        duration,
        panelClass: ["green-snackbar"],
      });
    } else {
      this.snackbar.open(msg, action, {
        duration,
        panelClass: ["green-snackbar-bottom"],
      });
    }
  }

  errorMsg(msg: string, action: string | undefined = undefined, duration = 5000) {
    this.snackbar.open(msg, action, {
      duration,
      panelClass: ["red-snackbar"],
    });
  }
}
