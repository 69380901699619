import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslocoModule } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { BrainContentType, EmbeddingFile, EmbeddingFileExtension, EmbeddingFileStatus } from "app/app.model";
import { getFileIconPath } from "app/core/functions/helper-functions";
import { PipesModule } from "app/pipes/pipes.module";
import { BaseComponent } from "../base-component/base-component.component";

@Component({
  selector: "app-brain-content",
  standalone: true,
  imports: [
    MatButtonModule,
    NgIf,
    MatProgressSpinnerModule,
    TranslocoModule,
    MatTooltipModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    PipesModule,
  ],
  templateUrl: "./brain-content.component.html",
  styleUrls: ["./brain-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrainContentComponent extends BaseComponent implements OnInit {
  @Input({ required: true })
  content!: EmbeddingFile;
  @Input() class = "";
  @Input() deleteInProgress = false;
  @Output() isProcessing = new EventEmitter<boolean>();
  @Output() delete = new EventEmitter<EmbeddingFile>();

  BrainContentType = BrainContentType;
  status = EmbeddingFileStatus;
  EmbeddingFileExtension = EmbeddingFileExtension;
  isDisabled = false;

  ngOnInit() {
    this.isProcessing.emit(true);
    if (this.content.contentType !== BrainContentType.Website && !this.content.id) {
      this.isDisabled = true;
      this.cdr.markForCheck();
    }
    if (this.content.status === this.status.Completed) {
      this.isProcessing.emit(false);
      this.cdr.markForCheck();
    }

    this.checkForFileStatusUpdate();
  }

  deleteContent() {
    this.content.isDeleting = true;
    this.delete.emit(this.content);
    this.cdr.markForCheck();
  }

  checkForFileStatusUpdate() {
    AppStore.addedBrainContents$.subscribe((value) => {
      const potentiallyUpdatedFile = value?.find((x) => x.metadata?.author === this.content.metadata?.author);
      if (potentiallyUpdatedFile) {
        this.content.status = potentiallyUpdatedFile.status;
        this.cdr.markForCheck();
      }
    });
  }

  getFileIconPath = getFileIconPath;
}
