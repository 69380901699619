/* eslint-disable max-lines */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { ConversationService } from "app/services/conversation.service";
import { Conversation } from "./api-models/conversation.models";
import { MarkdownService } from "ngx-markdown";
import {
  Observable,
  Subject,
  map,
  mergeMap,
  of,
} from "rxjs";
import { AppStore } from "./app-store.service";
import {
  BubblePostResp,
  InternetSearchStatus,
} from "./app.model";
import {
  APPROACHING_QUESTION_LIMIT,
  LS_TOKEN,
  LS_USER_ID,
  MONTHLY_QUESTION_LIMIT_THRESHOLD,
  REACHED_QUESTION_LIMIT,
  UNSUB_ID,
} from "./const/app-constant";
import { getUserData } from "./core/functions/helper-functions";
import {
  ApiResponse,
  HttpClientService,
  getDefaultApiResponseObj,
} from "./core/modules/http-client/http-client.service";
import { environment } from "./environments/environment";
import { Brain } from "./pages/dashboard/dashboard.model";
import { MyBrainPlans, UserData, UserDataLimits } from "./pages/home/home.model";
import {
  BrainPersona,
  ChatDataRole,
  ChatQueryResponse,
  GetAssistantResponse,
  UpsertThreadEmbeddingFiles
} from "./pages/my-brain/my-brain.model";
import { FileAnalysisService } from "./services/file-analysis.service";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor(
    private http: HttpClientService,
    private https: HttpClient,
    private conversationService: ConversationService,
    private fileAnalysisService: FileAnalysisService,
    private translocoService: TranslocoService,
    private markdownService: MarkdownService,
  ) {}

  public dashboardSubject: Subject<boolean> = new Subject<boolean>();

  formatResponse(responseText: string) {
    return this.markdownService.parse(responseText);
  }

  async askQueryFromBrainContent(
    query: string,
    brain: Brain | null,
    isEmbeddedChat: boolean,
    embeddedChatCreator: string,
  ) {
    let userId: string | null;
    if (isEmbeddedChat) {
      userId = embeddedChatCreator;
    } else {
      const userData = getUserData() as UserData;
      userId = userData.id;
    }

    if (!userId) {
      console.warn("No User id set");
      return;
    }

    const chatData: Conversation = {
      role: ChatDataRole.user,
      text: query,
      creationDate: new Date(),
      formattedContent: query,
      project: brain?.id,
    } as Conversation;

    const chats = [...AppStore.allChats$.value, chatData];

    AppStore.allChats$.next([
      ...chats,
      {
        role: ChatDataRole.assistant,
        text: "",
        creationDate: new Date(),
        formattedContent: "",
        project: brain?.id,
      } as Conversation,
    ]);

    let latitude = 0;
    let longitude = 0;

    try {
      const positionPromise = new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const positionData = await positionPromise;
      if (positionData) {
        latitude = (positionData as any).coords.latitude;
        longitude = (positionData as any).coords.longitude;
      }
    } catch (error) {
      console.warn("User blocked location access");
      console.warn(error);
    }

    const requestData = {
      openAIKey: "none",
      query: query,
      prompt: "yes",
      search: "no",
      provider: "openai",
      instruction: " ",
      useAssistantApi: !!brain?.openAIThreadId,
      threadId: brain?.openAIThreadId,
      assistantId: brain?.openAIAssistantId,
      interactiveInternetSearch: brain?.interactiveInternetSearch,
      messages: chats.map(({ role, text, metadata }) => ({ role, text, metadata })),
      chatGPT: "yes",
      explain_system:
        "You are an intelligent assistant, whose primary job is to provide the most accurate and truthful answer to a question from a user. You can speak any language and ONLY respond in the same language as the question being asked. You will only answer a question if it can be determined from the context provided.",
      step_1:
        "Follow ALL the numbered steps provided. Going step by step, before providing a final answer to the user.\n\n(1) Read and understand, in depth, the below context.",
      step_2:
        // eslint-disable-next-line quotes
        '(2) Determine an answer to the below question using only the context text. Very importantly, if the answer is not contained within the context above you should say "<IDK> I\'m sorry, I don\'t know the answer to that question. Please try rephrasing your question.". Your entire response MUST be in the same language as the question below.\n- Your answer MUST never refer to the "context" or "text"',
      results: 3,
      type: "summary",
      includeValues: "false",
      includeMetadata: "true",
      pineconeIndexName: environment.pineconeIndexName,
      user: userId,
      namespace: brain?.id,
      latitude: latitude,
      longitude: longitude,
    };

    const token = localStorage.getItem(LS_TOKEN);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const requestOptions = {
      headers: new HttpHeaders(headers),
    };

    const requestObservable = isEmbeddedChat
      ? this.https.post<any>(`${environment.apiBaseUrlAI}/projects/query`, requestData, requestOptions)
      : this.http.post<ChatQueryResponse>(`${environment.apiBaseUrlAI}/projects/query`, requestData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    requestObservable
      .pipe(
        mergeMap((resp) => {
          let answer = resp.answer || resp.data.answer;
          let references = [];
          if (resp?.data?.references && typeof resp.data.references == "object") {
            references = Object.values(resp.data.references)?.map((x: any) => {
              if (x?.length && typeof x == "object") {
                return x[0].url;
              } else if (typeof x == "string") {
                return x;
              }
            });
            references = references.filter((x) => x != undefined);
          }
          const metadata = resp?.metadata || resp?.data?.metadata || {};
          const assistantResponse = GetAssistantResponse(answer);
          if (assistantResponse) {
            answer = this.translocoService.translate(assistantResponse.translationKey);
          }

          const conversation: Conversation = {
            project: isEmbeddedChat ? "" : brain?.id,
            references: references,
            role: ChatDataRole[ChatDataRole.assistant],
            text: answer as string,
            createdBy: userId,
            imageUrls: resp?.data?.imageUrls || [],
            metadata: JSON.stringify(metadata),
          } as Conversation;

          const createMessageObservable = this.conversationService.create(conversation);
          return createMessageObservable.pipe(map((createMessageResp) => ({ resp, createMessageResp })));
        }),
      )
      .subscribe(({ resp, createMessageResp }) => {
        const chatId = createMessageResp.data?.id as string;
        let references = [];
        if (resp?.data?.references && typeof resp.data.references == "object") {
          references = Object.values(resp.data.references)?.map((x: any) => {
            if (x?.length && typeof x == "object") {
              return x[0].url;
            } else if (typeof x == "string") {
              return x;
            }
          });
          references = references.filter((x) => x != undefined);
        }
        const metadata = resp?.metadata || resp?.data?.metadata || {};
        if (resp.answer || (resp.isSuccess && resp.data)) {
          const formattedResponse = this.formatResponse(resp.answer || resp.data.answer);
          let answer = resp.answer || resp.data.answer;
          const assistantResponse = GetAssistantResponse(answer);
          if (assistantResponse) {
            answer = this.translocoService.translate(assistantResponse.translationKey);
          }

          AppStore.allChats$.next([
            ...AppStore.allChats$.value.filter((c) => c.text),
            {
              role: ChatDataRole.assistant,
              text: answer,
              creationDate: new Date(),
              references: references,
              formattedContent: formattedResponse,
              isAssistantQuestion: !!assistantResponse,
              id: chatId,
              project: brain?.id,
              imageUrls: resp?.data?.imageUrls || [],
              metadata: metadata,
            } as Conversation,
          ]);
        } else {
          AppStore.allChats$.next([
            ...AppStore.allChats$.value.filter((c) => c.text),
            {
              role: ChatDataRole.assistant,
              text: "Sorry we seem to be experiencing some problem processing your query! Please try again",
              creationDate: new Date(),
              formattedContent: "Sorry we seem to be experiencing some problem processing your query! Please try again",
              id: chatId,
              project: brain?.id,
            } as Conversation,
          ]);
        }
        if (brain) {
          AppStore.chatApiInProgress$.next({ brainId: brain?.id, inProgress: false });
        }
      });
  }

  async askQueryFromChatGPTStream(query: string, brainId: string, prevChats: Conversation[], isEmbeddedChat: boolean, persona: BrainPersona): Promise<Conversation> {
    try {
      const userId = localStorage.getItem(LS_USER_ID);
      const token = localStorage.getItem(LS_TOKEN);
      let latitude = 0;
      let longitude = 0;

      try {
        const positionPromise = new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const positionData = await positionPromise;
        if (positionData) {
          latitude = (positionData as any).coords.latitude;
          longitude = (positionData as any).coords.longitude;
        }
      } catch (error) {
        console.warn("User blocked location access");
        console.warn(error);
      }

      const response = await fetch(`${environment.apiBaseUrlAI}/projects/ask-question-internet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          openAIKey: "none",
          query: query,
          userId,
          brainId,
          prev_data: prevChats.map(({ role, text, metadata }) => {
            return { role, text, metadata } as Partial<Conversation>;
          }),
          latitude: latitude,
          longitude: longitude,
          persona: persona,
        }),
      });

      const reader = response?.body?.getReader();
      let answer = "";
      let references = [];
      let metadata = {};
      if (reader) {
        const decoder = new TextDecoder();
        let buffer = "";
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
            break;
          }

          buffer += decoder.decode(value, { stream: true });
          let separator;
          while ((separator = buffer.indexOf("\n\n")) !== -1) {
            const message = buffer.slice(0, separator);
            buffer = buffer.slice(separator + 2);
            if (message.startsWith("event: message")) {
              const encoded_content = message.split("\ndata: ")[1];
              const decodedUtf8Text = this.decodeEncodedUtf8Text(encoded_content);
              answer += decodedUtf8Text;
              this.processSseData(decodedUtf8Text);
            } else if (message.startsWith("event: metadata")) {
              const encoded_content = message.split("\ndata: ")[1];
              metadata = JSON.parse(atob(encoded_content)); // Base64 decoding
            } else if (message.startsWith("event: stop")) {
              const encoded_content = message.split("\ndata: ")[1];
              references = JSON.parse(atob(encoded_content)); // Base64 decoding
              AppStore.internetSearchStatus$.next(InternetSearchStatus.searching);
            } else if (message.startsWith("event: status")) {
              const encoded_content = message.split("\ndata: ")[1];
              const decodedUtf8Text = this.decodeEncodedUtf8Text(encoded_content);
              AppStore.internetSearchStatus$.next(decodedUtf8Text as InternetSearchStatus);
            }
          }
        }
      }

      const conversation: Conversation = {
        role: ChatDataRole[ChatDataRole.assistant],
        text: answer as string,
        creationDate: new Date(),
        formattedContent: this.formatResponse(answer),
        project: isEmbeddedChat ? "" : brainId,
        references: references,
        createdBy: userId,
        metadata: JSON.stringify(metadata),
      } as Conversation;

      const createMessageObservable = this.conversationService.create(conversation);

      if (answer) {
        createMessageObservable.subscribe((resp) => {
          const updatedMessage = {
            ...conversation,
            id: resp.data?.id,
          } as Conversation;

          AppStore.allChats$.next([...AppStore.allChats$.value.filter((c) => c.text), updatedMessage]);
        });
      } else {
        AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
        createMessageObservable.subscribe((resp) => {
          AppStore.allChats$.next([
            ...AppStore.allChats$.value.filter((c) => c.text),
            {
              role: ChatDataRole.assistant,
              text: "Sorry we seem to be experiencing some problem processing your query! Please try again",
              creationDate: new Date(),
              formattedContent: "Sorry we seem to be experiencing some problem processing your query! Please try again",
              id: resp.data?.id,
            } as Conversation,
          ]);
        });
      }
      return conversation;
    } catch (e) {
      const conversation = {
        role: ChatDataRole.assistant,
        text: "Sorry we seem to be experiencing some problem processing your query! Please try again",
        creationDate: new Date(),
        formattedContent: "Sorry we seem to be experiencing some problem processing your query! Please try again",
      } as Conversation;
      AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
      AppStore.allChats$.next([
        ...AppStore.allChats$.value.filter((c) => c.text),
        conversation
      ]);

      return conversation;
    } finally {
      AppStore.answer$.next("");
      AppStore.answerStream$.next({ stop: true, char: "" });
    }
  }

  decodeEncodedUtf8Text(encoded_content: string): string {
    const binaryString = atob(encoded_content);
    const decodedUtf8Text = new TextDecoder("utf-8").decode(
      new Uint8Array([...binaryString].map((char) => char.charCodeAt(0))),
    );
    return decodedUtf8Text;
  }

  processSseData(data: string) {
    for (const char of data) {
      AppStore.answerStream$.next({ stop: false, char }); // Emit each character into the subject
    }
  }

}
