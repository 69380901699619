import { Injectable } from "@angular/core";
import { HttpClientService } from "app/core/modules/http-client/http-client.service";
import { environment } from "app/environments/environment";
import { Conversation } from "app/api-models/conversation.models";

@Injectable({
  providedIn: "any",
})
export class ConversationService {
  constructor(private http: HttpClientService) {}

  getBrainConversation(brainId: string, page = 1, pageSize = 100000) {
    return this.http.get<Conversation[]>(
      // eslint-disable-next-line max-len
      `${environment.dotNetBaseUrl}/api/conversation/getBy?ProjectId=${brainId}&page=${page}&pageSize=${pageSize}&orderBy=creationDate&orderType=1`,
    );
  }

  update(conversation: Conversation) {
    return this.http.put<Conversation>(`${environment.dotNetBaseUrl}/api/conversation`, conversation);
  }

  create(conversation: Conversation) {
    return this.http.post<Conversation>(`${environment.dotNetBaseUrl}/api/conversation`, conversation);
  }

  deleteBrainConversations(brainId: string) {
    return this.http.delete<boolean>(
      `${environment.dotNetBaseUrl}/api/conversation/delete-brain-conversations?brainId=${brainId}`,
    );
  }
}
