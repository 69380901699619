export interface ChatQueryResponse {
  answer: string;
  finalPrompt: string;
  dataReferences: Reference[];
  references?: string[];
  assistantImageIds?: string[];
  id: string;
  ImageUrls?: string[];
  document_generated_response: boolean;
}

export interface Reference {
  author: string;
  content: string;
  img: string;
  link: string;
  score: number;
  section: string;
  time_stamp: number;
  title: string;
}

export enum ChatDataRole {
  user = "user",
  assistant = "assistant",
}

export interface SuggestedQuestionTranslation {
  data: {
    translations: {
      translatedText: string;
      detectedSourceLanguage: string;
    }[];
  };
}

export interface UpsertThreadEmbeddingFiles {
  id: string | undefined;
  fileUrl: string;
}

export interface BrainChat {
  brainId: string;
  inProgress: boolean;
}

interface AssistantSpecialResponse {
  key: string;
  translationKey: string;
}

export const AssistantSpecialResponses: AssistantSpecialResponse[] = [
  { key: "<would_you_like_to_search_the_internet>", translationKey: "wouldYouLikeToSearchTheInternet" },
  { key: "<i_dont_know_the_answer>", translationKey: "iDontknowTheAnswer" },
  { key: "<acknowledged_lets_continue_our_conversation>", translationKey: "acknowledgedLetsContinueOurConversation" },
];

export function GetAssistantResponse(key: string) {
  return AssistantSpecialResponses.find((x) => x.key == key);
}

export interface SuggestedQuestionTranslation {
  data: {
    translations: {
      translatedText: string;
      detectedSourceLanguage: string;
    }[];
  };
}

export interface AssistantApiResponse {
  assistantId: string;
}

export interface ThreadApiResponse {
  thread_id: string;
}

export interface UpsertThreadEmbeddingFiles {
  id: string | undefined;
  fileUrl: string;
}

interface AssistantSpecialResponse {
  key: string;
  translationKey: string;
}

export interface BrainChat {
  brainId: string;
  inProgress: boolean;
}

export type BrainPersona = "standard" | "legal" | "medical";
