import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LS_TOKEN, LS_USER_ID } from "app/const/app-constant";
import { environment } from "app/environments/environment";
import { AssistantApiResponse, ThreadApiResponse, UpsertThreadEmbeddingFiles } from "app/pages/my-brain/my-brain.model";
@Injectable({
  providedIn: "root",
})
export class FileAnalysisService {
  constructor(private http: HttpClient) {}

  upsertAssistant() {
    const userId = localStorage.getItem(LS_USER_ID);
    const token = localStorage.getItem(LS_TOKEN);

    return this.http.put<AssistantApiResponse>(
      `${environment.apiBaseUrlAI}/projects/assistant`,
      {
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );
  }

  deleteThread(threadId: string) {
    const token = localStorage.getItem(LS_TOKEN);

    return this.http.post<AssistantApiResponse>(
      `${environment.apiBaseUrlAI}/projects/assistant/thread/delete`,
      {
        threadId: threadId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );
  }

  deleteFile(fileId: string) {
    return this.http.put<AssistantApiResponse>(`${environment.apiBaseUrlAI}/projects/assistant/thread/file/delete`, {
      id: fileId,
    });
  }

  upsertThread(files: UpsertThreadEmbeddingFiles[], embeddingProjectId: string, threadId?: string) {
    const token = localStorage.getItem(LS_TOKEN);

    return this.http.put<ThreadApiResponse>(
      `${environment.apiBaseUrlAI}/projects/assistant/thread`,
      {
        threadId: threadId ?? "",
        files,
        embeddingProjectId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );
  }
}
