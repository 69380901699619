import { NgModule } from "@angular/core";
import { RouterModule, Routes, TitleStrategy } from "@angular/router";
import { EmbeddedChatComponent } from "app/core/components/embedded-chat/embedded-chat.component";
import {
  ACCOUNT_DETAILS_ROUTE,
  AFFILIATE_COMPLETE_ROUTE,
  AFFILIATE_ROUTE,
  BUSINESS_LANDING_PAGE_ROUTE,
  CHAT_WINDOW,
  CONTENT_ROUTE_ID,
  CRAWLED_PAGES_ROUTE,
  DASHBOARD_ROUTE,
  EMBEDDED_CHAT,
  HOME_ROUTE,
  MY_BRAIN_ROUTE,
  SHARE_BRAIN_ROUTE_ID,
} from "./const/app-constant";
import { ChatWindowComponent } from "./core/components/chat-window/chat-window.component";
import { tokenGuard } from "./guards/token.guard";
import { UpdateTitle } from "app/services/update-title.service";
const routes: Routes = [
  {
    path: EMBEDDED_CHAT,
    component: EmbeddedChatComponent,
  },
  {
    path: CHAT_WINDOW,
    component: ChatWindowComponent,
  },
  {
    path: "spinner",
    loadComponent: () =>
      import(/* webpackChunkName: "style-guide" */ "./pages/spinner/spinner.component").then((c) => c.SpinnerComponent),
  },
  {
    path: "",
    loadComponent: () =>
      import(/* webpackChunkName: "layout" */ "./layout/layout.component").then((c) => c.LayoutComponent),
    children: [
      {
        path: HOME_ROUTE,
        loadComponent: () =>
          import(/* webpackChunkName: "home" */ "./pages/home/home.component").then((c) => c.HomeComponent),
      },
      {
        path: BUSINESS_LANDING_PAGE_ROUTE,
        loadComponent: () =>
          import(
            /* webpackChunkName: "business-landing-page-pages" */ "./pages/business-landing-page/business-landing-page.component"
          ).then((c) => c.BusinessLandingPageComponent),
      },
      {
        path: DASHBOARD_ROUTE,
        loadComponent: () =>
          import(/* webpackChunkName: "dashboard" */ "./pages/dashboard/dashboard.component").then(
            (c) => c.DashboardComponent,
          ),
        title: "title.myBrains",
      },
      {
        path: AFFILIATE_ROUTE,
        loadComponent: () =>
          import(/* webpackChunkName: "dashboard" */ "./core/components/affiliate/affiliate.component").then(
            (c) => c.AffiliateComponent,
          ),
      },
      {
        path: AFFILIATE_COMPLETE_ROUTE,
        loadComponent: () =>
          import(
            /* webpackChunkName: "dashboard" */ "app/core/components/affiliate-complete/affiliate-complete.component"
          ).then((c) => c.AffiliateCompleteComponent),
      },
      {
        path: CONTENT_ROUTE_ID,
        loadComponent: () =>
          import(/* webpackChunkName: "content" */ "./core/components/content/content.component").then(
            (c) => c.ContentComponent,
          ),
        title: "title.content",
      },
      {
        path: ACCOUNT_DETAILS_ROUTE,
        loadComponent: () =>
          import(/* webpackChunkName: "create-account" */ "./pages/account-details/account-details.component").then(
            (c) => c.AccountDetailsComponent,
          ),
        title: "title.accountDetails",
      },
      {
        path: SHARE_BRAIN_ROUTE_ID,
        loadComponent: () =>
          import(/* webpackChunkName: "share-brain" */ "./pages/share-brain/share-brain.component").then(
            (c) => c.ShareBrainComponent,
          ),
        title: "title.shared",
      },
      {
        path: MY_BRAIN_ROUTE,
        loadComponent: () =>
          import(/* webpackChunkName: "my-brain" */ "./pages/my-brain/my-brain.component").then(
            (c) => c.MyBrainComponent,
          ),
        title: "title.conversation",
      },
      {
        path: BUSINESS_LANDING_PAGE_ROUTE,
        loadComponent: () =>
          import(
            /* webpackChunkName: "business-landing-page-pages" */ "./pages/business-landing-page/business-landing-page.component"
          ).then((c) => c.BusinessLandingPageComponent),
      },
      {
        path: "**",
        redirectTo: HOME_ROUTE,
        pathMatch: "full",
      },
      {
        path: BUSINESS_LANDING_PAGE_ROUTE,
        loadComponent: () =>
          import(
            /* webpackChunkName: "business-landing-page-pages" */ "./pages/business-landing-page/business-landing-page.component"
          ).then((c) => c.BusinessLandingPageComponent),
      },
      {
        path: "**",
        redirectTo: HOME_ROUTE,
        pathMatch: "full",
      },
      {
        path: BUSINESS_LANDING_PAGE_ROUTE,
        loadComponent: () =>
          import(
            /* webpackChunkName: "business-landing-page-pages" */ "./pages/business-landing-page/business-landing-page.component"
          ).then((c) => c.BusinessLandingPageComponent),
      },
      {
        path: "**",
        redirectTo: HOME_ROUTE,
        pathMatch: "full",
      },
    ],
    canActivate: [tokenGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: UpdateTitle }],
})
export class AppRoutingModule {}
