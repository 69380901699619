import { CanActivateFn } from "@angular/router";
import { LS_TOKEN, LS_USER_ID, SSO_TOKEN } from "app/const/app-constant";

//This is used in case you want to pass a session for keeping the login active
// over different interfaces (mobile <> web)
export const tokenGuard: CanActivateFn = (route) => {
  const loginToken = route.queryParams[SSO_TOKEN];
  const userId = route.queryParams[LS_USER_ID];
  if (loginToken && userId) {
    localStorage.setItem(LS_TOKEN, loginToken);
    localStorage.setItem(LS_USER_ID, userId);
  }
  return true;
};
