import { Injectable } from "@angular/core";
import { HttpClientService } from "app/core/modules/http-client/http-client.service";
import { environment } from "app/environments/environment";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { GenericBAResponse } from "app/app.model";

@Injectable({
  providedIn: "any",
})
export class EmbeddingProjectsService {
  constructor(private http: HttpClientService) {}

  get(id: string, isAuth = true) {
    return this.http.get<Brain>(`${environment.dotNetBaseUrl}/api/embeddingProject/${id}`, { isAuth: isAuth });
  }
  update(brain: Brain) {
    return this.http.put<Brain>(`${environment.dotNetBaseUrl}/api/embeddingProject`, brain);
  }

  create(brain: Brain) {
    return this.http.post<Brain>(`${environment.dotNetBaseUrl}/api/embeddingProject`, brain);
  }

  delete(id: string, embeddingFilesIds: string[] = []) {
    return this.http.delete<boolean>(
      `${environment.dotNetBaseUrl}/api/embeddingProject/delete?id=${id}`,
      embeddingFilesIds,
    );
  }

  getUserBrains(userId: string, page = 1, pageSize = 100000) {
    return this.http.get<GenericBAResponse<Brain>>(
      `${environment.dotNetBaseUrl}/api/embeddingProject/getBy?userId=${userId}&page=${page}&pageSize=${pageSize}&orderBy=creationDate&orderType=2`,
    );
  }
}
