<h2 mat-dialog-title class="dialog-head">
  <div class="dialog-head flex justify-between items-center" *transloco="let t; read: 'addMyBrainContent'">
    <div class="text-lg font-medium text-black-dark">
      {{ t("addYourContent") }}
    </div>
    <button mat-button [disabled]="isUploading || !selectedBrains" (click)="closeDialog()">
      <img src="./assets/images/icons/close.svg" />
    </button>
  </div>
</h2>
<mat-dialog-content class="mat-typography overflow-x-auto !max-h-none !pl-4">
  <div class="w-full p-1 lg:min-w-[980px] overflow-hidden" *transloco="let t; read: 'addMyBrainContent'">
    <div class="flex gap-2 flex-col lg:flex-row">
      <div class="w-full lg:w-1/2">
        <app-web-page-upload
          (isUploading)="isUploadingEvent($event)"
          (webBrainContents)="onWebBrainContents($event)"
          #webPageUploadComponent
        ></app-web-page-upload>
      </div>
      <div class="w-full lg:w-1/2">
        <app-file-upload
          (isUploading)="isUploadingEvent($event)"
          (fileBrainContents)="onFileBrainContents($event)"
          #fileUploadComponent
        ></app-file-upload>
      </div>
    </div>
  </div>
</mat-dialog-content>
<app-user-limit
  [isHidden]="isHidden"
  (updateIsHidden)="updateIsHidden($event)"
  class="w-full"
  (shouldCloseDialog)="shouldCloseDialog()"
></app-user-limit>
<div *transloco="let t; read: 'addMyBrainContent'" class="sticky bottom-0 bg-white z-10">
  <mat-dialog-actions align="end">
    <button mat-button (click)="closeDialog()" class="px-0" [disabled]="isUploading || !selectedBrains">
      {{ t("cancel") }}
    </button>
    <button
      mat-raised-button
      extended
      color="primary"
      (click)="addContent()"
      [disabled]="contents.length <= 0 || areAllFilesInErrorState()"
      class="flex flex-row-reverse"
    >
      {{ t("addContent") }}
      <mat-icon class="!ml-1.5">arrow_forward</mat-icon>
    </button>
  </mat-dialog-actions>
</div>