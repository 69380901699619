import { NavigationEnd, Router } from "@angular/router";
import { Component, HostListener, Inject, OnInit, isDevMode } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { environment } from "app/environments/environment";
import { GoogleAnalyticsService } from "app/google-analytics.service";
import { ChatBotService } from "app/services/chat-bot.service";
import { filter } from "rxjs/operators";
import { NewVersionDialogComponent } from "app/core/components/new-version-dialog/new-version-dialog.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private scriptInjected = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.handleChatBot();
  }

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private chatBotService: ChatBotService,
    private router: Router,
    private swUpdate: SwUpdate,
    @Inject(MatDialog) private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.checkForChatBotScript();
    this.checkForUpdates();
  }

  private checkForChatBotScript(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.handleChatBot();
    });
  }

  private handleChatBot(): void {
    const currentRoute = this.router.url;

    if (currentRoute === "/" || currentRoute.startsWith("/dashboard")) {
      this.pageWidthCheck();
    } else {
      this.hideChatBot();
    }
  }

  private pageWidthCheck(): void {
    const windowWidth = window.innerWidth;

    if (windowWidth < 768) {
      this.hideChatBot();
    } else {
      this.injectChatScriptOnce();
    }
  }

  private injectChatScriptOnce(): void {
    if (!this.scriptInjected) {
      const scriptSrc = `${environment.baseUrl}/assets/scripts/embed-chat-js-min.js`;
      const dataId = environment.brainHelpId;
      this.chatBotService.showChatBot(scriptSrc, dataId);
      this.scriptInjected = true;
    }
  }

  private hideChatBot(): void {
    this.chatBotService.hideChatBot();
    this.scriptInjected = false;
  }

  private checkForUpdates(): void {
    if (isDevMode()) return;
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"))
      .subscribe((evt) => {
        console.log("new version available");
        this.showUpdateDialog();
      });
    this.swUpdate.checkForUpdate();
  }

  private showUpdateDialog(): void {
    const dialogRef = this.dialog.open(NewVersionDialogComponent, {
      panelClass: "update-dialog",
      position: { top: "2rem" },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        window.location.reload();
      }
    });
  }
}
