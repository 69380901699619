<div class="bg-[#f8e4a4] flex flex-row justify-center md:h-[68px] h-auto p-2" *ngIf="isHidden">
  <div class="container" *transloco="let t; read: 'limits'">
    <div
      class="flex md:flex-row flex-col h-full items-center justify-between"
      [class.scale-x-[-1]]="activeLang === 'ar_ar'"
    >
      <div class="md:w-[60%] w-full">
        <span>
         {{
            !isMobileApp()
              ? userDataLimits?.isContentExceeded && !userDataLimits?.isQuestionExceeded
                ? t("fileLimit")
                : !userDataLimits?.isContentExceeded && userDataLimits?.isQuestionExceeded
                ? t("questionLimit")
                : t("bothLimit")
              : userDataLimits?.isContentExceeded && !userDataLimits?.isQuestionExceeded
              ? t("fileLimitNativeApp")
              : !userDataLimits?.isContentExceeded && userDataLimits?.isQuestionExceeded
              ? t("questionLimitNativeApp")
              : t("bothLimitNativeApp")
          }}
        </span>
      </div>
      <div *ngIf="!isEmbeddedChat" class="md:w-[40%] w-full justify-end flex flex-row items-center gap-3">
        <button (click)="upgradeAccount()" mat-stroked-button class="w-auto h-[70px] !bg-white !rounded-[8px]">
          {{ t(!isMobileApp() ? "upgrade" : "upgradeNativeApp") }}
        </button>
        <button (click)="hideMessage()">
          <img src="./assets/images/icons/close.svg" />
        </button>
      </div>
    </div>
  </div>
</div>
