import { Clipboard } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslocoModule, TranslocoPipe } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { InternetSearchStatus, KeyValueBooleanObject } from "app/app.model";
import { CharacterFormatter } from "app/core/classes/character-formatter";
import { AskMyBrainSearchComponent } from "app/core/components/ask-my-brain-search/ask-my-brain-search.component";
import { BaseHttpComponent } from "app/core/components/base-http/base-http.component";
import {
  BrainStyle,
} from "app/core/components/embedded-chat/embedded-chat.model";
import { FollowUpQuestionComponent } from "app/core/components/follow-up-question/follow-up-question.component";
import { NoBrainComponent } from "app/core/components/no-brain/no-brain.component";
import { UserLimitComponent } from "app/core/components/user-limit/user-limit.component";
import { addAlpha } from "app/core/functions/helper-functions";
import { isAndroidApp } from "app/core/modules/mobile-interfaces/app-initialization-interface";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { ChatDataRole, SuggestedQuestionTranslation } from "app/pages/my-brain/my-brain.model";
import { EmbeddingProjectsService } from "app/services/embedding-projects.service";
import { UserDataLimitsService } from "app/services/user-data-limits.service";
import { UsersService } from "app/services/users.service";
import { environment } from "app/environments/environment";
import { HeaderComponent } from "app/layout/components/header/header.component";
import { UserDataLimits } from "app/pages/home/home.model";
import { PipesModule } from "app/pipes/pipes.module";
import { MarkdownModule } from "ngx-markdown";
import { concatMap, distinctUntilChanged, map, timer } from "rxjs";

@Component({
  selector: "app-embedded-chat",
  standalone: true,
  imports: [
    CommonModule,
    FollowUpQuestionComponent,
    PipesModule,
    TranslocoPipe,
    TranslocoModule,
    AskMyBrainSearchComponent,
    MatSnackBarModule,
    HeaderComponent,
    UserLimitComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    NoBrainComponent,
    MarkdownModule,
  ],
  templateUrl: "./embedded-chat.component.html",
  styleUrls: ["./embedded-chat.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EmbeddedChatComponent extends BaseHttpComponent implements OnInit {
  protected readonly InternetSearchStatus = InternetSearchStatus;
  chats$ = AppStore.allChats$;
  answer$ = AppStore.answer$;
  brain!: Brain;
  role = ChatDataRole;
  activeLang = "";
  brainId!: string;
  chatAccordionOpen: KeyValueBooleanObject<number> = {};
  suggestedQuestions: string[] = [];
  hasContent = false;
  internetSearchStatus = InternetSearchStatus.searching;
  followuppQuestions: string[] = [];
  private readonly formatter = new CharacterFormatter();
  @ViewChild("chatContainer") private chatContainer!: ElementRef;
  scrolledUp = false;
  brainStyle?: BrainStyle;
  isCustomStylingEnabled?: boolean;
  isHidden = false;
  embeddedChatCreator = "";
  isLoading = true;
  showScrollButton = false;

  constructor(
    private https: HttpClient,
    private element: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private clipboard: Clipboard,
    private embeddingProjectService: EmbeddingProjectsService,
    private userService: UsersService,
    private userDataLimitsService: UserDataLimitsService,
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.brainId = params["id"];
      AppStore.selectedBrainId$.next(this.brainId);
    });

    this.subs$.add(
      AppStore.selectedBrainId$.subscribe((_) => {
        this.loadData();
      }),
    );

    this.subs$.add(
      AppStore.selectedBrain$.subscribe((brain) => {
        if (brain) {
          this.getSuggestedQuestion();
          this.getUserData(brain);
        }
      }),
    );

    AppStore.answerStream$
      .pipe(concatMap((char) => timer(this.getDelayTime()).pipe(map(() => char))))
      .subscribe((char) => {
        if (char?.stop) {
          this.answer$.next("");
          this.formatter.reset();
        } else {
          AppStore.answer$.next(this.formatter.formatCharacter(char.char));
        }
      });

    this.answer$.subscribe(() => {
      try {
        if (this.chatContainer) {
          if (!this.scrolledUp) {
            this.renderer.setProperty(
              this.chatContainer.nativeElement,
              "scrollTop",
              this.chatContainer.nativeElement.scrollHeight,
            );
          }
        }
      } catch (err) {
        console.error(err);
      }
    });

    this.subs$.add(
      AppStore.chatApiInProgress$.subscribe(() => {
        setTimeout(() => {
          const ele = this.element.nativeElement.querySelector("#last-chat");
          if (ele) {
            ele.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 100);
      }),
    );

    this.subs$.add(
      AppStore.userData$.subscribe((userData) => {
        if (userData) {
          this.userDataLimitsService.checkUserLimit(userData);
        }
      }),
    );

    this.subs$.add(
      AppStore.userDataLimits$.subscribe((resp) => {
        if (resp) {
          this.isHidden = resp.isContentExceeded || resp.isQuestionExceeded;
        }
      }),
    );

    this.subs$.add(
      AppStore.selectedLanguage$.pipe(distinctUntilChanged()).subscribe((lang) => {
        this.activeLang = lang;
        this.getSuggestedQuestion();
        this.cdr.markForCheck();
      }),
    );
  }
  loadData() {
    this.getBrainContent();
    setTimeout(() => {
      const ele = this.element.nativeElement.querySelector("#last-chat");
      if (ele) {
        ele.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 1000);
  }
  async deleteMessages() {
    this.chats$.next([]);
  }
  askSuggestedQuestion(ques: string) {
    AppStore.askQuesFromSuggestedQuestion$.next(ques);
  }

  onScroll(event: any) {
    const element = event.target;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    this.showScrollButton = !atBottom;
    this.cdr.markForCheck();
  }

  scrollToBottom(): void {
    try {
      const chatContainer = this.chatContainer.nativeElement;
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
      this.showScrollButton = false;
    } catch (err) {
      console.error("Error while scrolling to bottom:", err);
    }
  }

  getBrainContent() {
    this.embeddingProjectService.get(this.brainId, false).subscribe((resp) => {
      if (resp && resp.data) {
        this.brain = resp.data;
        this.embeddedChatCreator = this.brain.createdBy;
        this.followuppQuestions = this.brain.followUpQuestions;
        this.suggestedQuestions = this.brain.suggestedQuestions;
        this.hasContent = this.brain.hasContent;
        this.brainStyle = {
          PrimaryColor: this.brain.styleBackgroundPrimaryColor,
          SecondaryColor: this.brain.styleBackgroundPrimaryColor,
        } as BrainStyle;
        this.isCustomStylingEnabled = this.brain.enableCustomStyling;
        this.isLoading = false;
        AppStore.selectedBrain$.next(this.brain);
        this.cdr.markForCheck();
      } else {
        this.isLoading = false;
        this.cdr.markForCheck();
      }
    });
  }
  private getDelayTime(): number {
    const charCount = AppStore.answer$.value.length;
    if (charCount < 10) return 25;
    if (charCount < 100) return 20;
    if (charCount < 300) return 10;
    return 0;
  }
  getSuggestedQuestion() {
    if (this.suggestedQuestions && this.suggestedQuestions.length !== 0) {
      const requestBody = {
        q: this.suggestedQuestions,
        target: this.activeLang === "ko_ko" ? "ko" : this.activeLang,
      };

      this.https
        .post<SuggestedQuestionTranslation>(
          "https://translation.googleapis.com/language/translate/v2?key=AIzaSyDIXTrZe71kFrqPqw-IcUBJ95ffdyVKUoc",
          requestBody,
        )
        .subscribe((response) => {
          if (response && response.data && response.data.translations) {
            const translations = response.data.translations;
            this.suggestedQuestions = translations.map((translation) => {
              let translatedText = translation.translatedText;
              if (this.activeLang === "es_es") {
                translatedText = translation.translatedText.replace(/cerebro/g, "Brain");
                translatedText = translatedText.replace(/Cerebral/g, "Brain");
              }
              return translatedText;
            });
            this.cdr.markForCheck();
          }
        });
    }
  }
  getUserData(brain: Brain) {
    this.userService.get(brain.createdBy, false).subscribe((resp) => {
      if (resp && resp.isSuccess && resp.data) {
        AppStore.userData$.next(resp.data);
      }
    });
  }
  copyToClipboard(text: string) {
    this.clipboard.copy(text);
    if (!isAndroidApp()) {
      // eslint-disable-next-line max-lines
      this.successMsg(this.transloco.translate("defaultFollowUpQuestion.successfulCopy"));
    }
  }

  protected readonly addAlpha = addAlpha;
}
